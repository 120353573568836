<template>
  <ion-page>
    <div>
      <ion-card>
        <ion-card-content>
            <ion-icon color="danger" :ios="alertCircle" :md="alertCircle" class="big-circle-alert"/>
            <h1 class="error-text">
              This app is not activated for your practice. <br/>
              Please contact your care provider.
            </h1>
        </ion-card-content>
      </ion-card>
      <ion-button color="danger" class="log-out-button" @click="logOut">
        <ion-spinner v-if="loggingOut"/>
        <div v-else class="log-out-button-inner">
          <ion-icon slot="start" :ios="logOutOutline" :md="logOutOutline"/>
          <div class="log-out-text">
            Sign Out
          </div>
        </div>
      </ion-button>
    </div>
  </ion-page>
</template>

<script>

import { defineComponent } from 'vue'
import { alertCircle } from 'ionicons/icons'
import {
  IonIcon,
  IonCard,
  IonButton,
  IonCardContent,
  IonSpinner,
  IonPage
} from '@ionic/vue'
import {
  logOutOutline,
} from 'ionicons/icons'

import { AUTH_LOGOUT } from '../store/actions/auth'

export default defineComponent({
  name: 'PracticeError',
  setup () {
    return {
      alertCircle,
      logOutOutline,
    }
  },
  data () {
    return {
      loggingOut: false,
    }
  },
  components: {
    IonIcon,
    IonCard,
    IonCardContent,
    IonSpinner,
    IonButton,
    IonPage,
  },
  methods: {
    logOut () {
      if (this.loggingOut) {return}
      this.loggingOut = true
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/')
        this.loggingOut = false
      })
    },
  },
})
</script>

<style scoped>
.big-circle-alert {
  margin-left: auto;
  margin-right: auto;
  display: block;
  zoom: 5.0;
}
.error-text {
  text-align: center;
  line-height: 1.5em;
}
.log-out-button {
  width: calc(100% - 50px);
  height: 48px;
  margin: 25px;
}
.log-out-button-inner {
  display: flex;
  align-items: center;
}
.log-out-button ion-icon {
  font-size: 24px;
  margin-right: 10px;
}
.log-out-text {
  font-size: 20px;
}
</style>
